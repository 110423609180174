import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Sidebar from './utils/sidebar'
import { Layout, message, Button } from 'antd'
import AddEntre from './factures/components/add'
import AddSortie from './factures/components/sorties/add'
import Customers from './customers'
import TableEntree from './factures/components';
import TableDevise from './devises';
import AddDevise from './devises/components/form';
import AddClient from './customers/components/forms';
import TableSortie from './factures/components/sorties/'
import FactureEntreeForValidate from './factures/components/entrevalidate'
import FactureSortieForValidate from './factures/components/sorties/sortievalidate'
import Users from './users';
import AddUsers from './users/components/form'
import RapportFacture from './factures/components/rapport'
import Retrait from './factures/components/retrait';
import Depot from './factures/components/depot';
import CompteClient from './factures/components/compteClient';
import EditDepot from './factures/components/updateOperation';
import TableTaux from './taux_jour';
import AddTaux from './taux_jour/components/form';
import TableDepense from './depenses/';
import TableIjectFond from './depenses/components/inject_fond';
import Rapport from './rapports'
import EditDepense from './depenses/components/edit';
import SoldesBureau from './rapports/soldes_bureau'
import ByClientInBureau from './rapports/in_bureau'
import ByClientHorsBureau from './rapports/hors_bureau'
import TableValidateDepot from './operations/validateDepot'
import TableValidateRetrait from './operations/validateRetrait'
import ValidateDepenseInject from './depenses/components/validate'
import Login from './auth/login'
import axios from './services/axios'
import Dashboard from './dashboard';
import Transation_by_client from './rapports/transation_by_client';
import RapportCreditPdf from './rapports/rapport_credit_dette_pdf';
import  { PRIMARY_COLOR } from './utils/constants'
const { Header, Content, Footer, Sider } = Layout;

function App() {
  const [user, setUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isRedirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    fetchUserConnected();
  }, []);

  async function fetchUserConnected() {
    try {
      const validate = localStorage.getItem('valideUser');
      const token = localStorage.getItem('token-bahtb-secret');
     
      if (token && validate == 'dms-valide') {
        setUser(true);
      }
      // const users = await Auth.currentAuthenticatedUser();
   
    } catch (error) {
      console.log("error", error);
    }
  }

  function signIn(values: any) {
    setLoading(true)
    try {

      axios.post('/login', values)
        .then(function (response) {
          localStorage.setItem('token-bahtb-secret', response.data.access_token);
          localStorage.setItem('valideUser', 'dms-valide');
          setRedirect(true)
          setUser(response.data);
        })
        .catch(function (error) {
          console.log(error);
          message.error('Identifiant ou mot de passe incorrect!');
          setLoading(false);
        });
    } catch (error) {
      message.error('Identifiant ou mot de passe incorrect!');
      console.log('error', error);
    }
  }

  const logout = () => {
    localStorage.setItem('token-bahtb-secret', '');
    localStorage.setItem('valideUser', '');
    setUser(false)
    setLoading(false)
    window.location.href = '/';
  }

  return (
    <>
      {!user ? (<Login onSubmit={signIn} isLoading={loading} />) : (
        <Layout>
          <Sider
            style={{ backgroundColor: PRIMARY_COLOR}}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}>

            <Sidebar />

          </Sider>

          <Layout>
            <Header   className="site-layout-sub-header-background" style={{ padding: 0, backgroundColor: PRIMARY_COLOR }} >
              <Button type='primary' danger onClick={logout} style={{ verticalAlign: 'middle', marginLeft: '90%' }} >
                Se deconnecter
              </Button>
            </Header>

            <Content style={{ margin: '24px 16px 0' }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: "100vh", background: '#fff' }}>
                <Switch>
                  <Route exact path="/">
                    <Dashboard />
                  </Route>
                  <Route exact path="/clients">
                    <Customers />
                  </Route>
                  <Route exact path="/clients/edit/:id">
                    <AddClient />
                  </Route>
                  <Route path="/dashboard">

                  </Route>

                  <Route exact path="/factures/add-entres">
                    <AddEntre />
                  </Route>
                  <Route exact path="/factures/add-sorties">
                    <AddSortie />
                  </Route>


                  <Route exact path="/factures-entres/edit/:id">
                    <AddEntre />
                  </Route>

                  <Route exact path="/factures-sorties/edit/:id">
                    <AddSortie />
                  </Route>

                  <Route exact path="/factures/entrees">
                    <TableEntree />
                  </Route>
                  <Route exact path="/factures/sorties">
                    <TableSortie />
                  </Route>
                  <Route exact path="/devises">
                    <TableDevise />
                  </Route>
                  <Route exact path="/add-devise">
                    <AddDevise />
                  </Route>
                  <Route exact path="/add-devise/edit/:id">
                    <AddDevise />
                  </Route>
                  <Route exact path="/add-clients">
                    <AddClient />
                  </Route>

                  <Route exact path="/entrees/valide">
                    <FactureEntreeForValidate />
                  </Route>
                  <Route exact path="/sorties/valide">
                    <FactureSortieForValidate />
                  </Route>

                  <Route exact path="/utilisateurs">
                    <Users />
                  </Route>
                  <Route exact path="/add-users">
                    <AddUsers />
                  </Route>
                  <Route exact path="/comptes-globales">
                    <RapportFacture />
                  </Route>
                  <Route exact path="/retrait">
                    <Retrait />
                  </Route>
                  <Route exact path="/depot">
                    <Depot />
                  </Route>
                  <Route exact path="/depot/edit/:id">
                    <EditDepot />
                  </Route>
                  <Route exact path="/compte-client">
                    <CompteClient />
                  </Route>
                  <Route exact path="/taux-jours">
                    <TableTaux />
                  </Route>
                  <Route exact path="/add-taux-jours">
                    <AddTaux />
                  </Route>
                  <Route exact path="/add-taux-jour/edit/:id">
                    <AddTaux />
                  </Route>

                  <Route exact path="/depenses">
                    <TableDepense />
                  </Route>

                  <Route exact path="/inject-fond/">
                    <TableIjectFond />
                  </Route>

                  <Route exact path="/depenses/edit/:id">
                    <EditDepense />
                  </Route>

                  <Route exact path="/rapports/credits">
                    <Rapport />
                  </Route>
                  <Route exact path="/rapports/soldes-bureau">
                    <SoldesBureau />
                  </Route>

                  <Route exact path="/rapports/clients/in-bureau">
                    <ByClientInBureau />
                  </Route>
                  <Route exact path="/rapports/clients/hors-bureau">
                    <ByClientHorsBureau />
                  </Route>

                  <Route exact path="/depot/valide">
                    <TableValidateDepot />
                  </Route>
                  <Route exact path="/retrait/valide">
                    <TableValidateRetrait />
                  </Route>
                  <Route exact path="/depenses/validate/depense-inject">
                    <ValidateDepenseInject />
                  </Route>

                   <Route exact path="/rapports/all-transations/by-client">
                    <Transation_by_client />
                  </Route>

                  <Route exact path="/rapports/rapport-credit-pdf">
                    <RapportCreditPdf />
                  </Route>

                  <Route exact path="/rapports/rapport-credit-pdf/by-client/:idclient">
                    <RapportCreditPdf />
                  </Route>
                  <Route exact path="/rapports/rapport-credit-pdf/by-devise/undefined/:iddevise">
                    <RapportCreditPdf />
                  </Route>
                  <Route exact path="/rapports/rapport-credit-pdf/:idclient/:iddevise">
                    <RapportCreditPdf />
                  </Route>
                  
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <span>
                 Copy right ©2024 Created by <a target="_blank" href="https://bimapgroup.com">Bimap group</a>
             </span>
              <span style={{ marginLeft: 10}}>
                    version 1.2  <span style={{ color: '#208938', float:'right'}}>Bah Transfert Bamako -BTB </span>
                </span>
            
            </Footer>
          </Layout>
        </Layout>
      )}
    </>
  );
}

export default App;


